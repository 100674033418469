import React, { useCallback, useState } from 'react'
import { Button, Modal, message as Notify } from 'antd'
import { useSelector } from 'react-redux'
import Api from '../../utils/Axios';

const archivedText = 'By archiving this department, department head will lost the access members in this department. The department will still show in reports. You can un-archive this department at any time'
const unarchivedText = 'By unarchiving this department, department head will gain the access members in this department. The department will still show in reports. You can archive this department at any time'

export default function DepartmentDeleteModal({ department, open, archived, onClose }) {

  const [loading, setLoading] = useState(false);
  const { activeOrganization } = useSelector((state) => state.auth);
  const handleDepartmentArchive = useCallback(async () => {
    try {
      setLoading(true)
      const { message } = await Api.Get(`/organization/${activeOrganization?.id}/department/${department?.id}/${archived ? 'restore' : 'archive'}`)
      Notify.success(message);
      onClose(true);
    } catch (error) {
      if (error.response && [400, 422].includes(error.response.status)) {
        Notify.error(error.response.data.message);
      } else {
        Notify.error('Something went wrong');
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [activeOrganization, department, onClose, archived])

  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      footer={false}
      destroyOnClose
    >
      <div className='md:m-10 flex flex-col items-center text-center font-poppins'>
        <p className='font-slightly-bold text-xl my-2'>Are you sure you want to {archived && 'Un'} Archive this department? </p>
        <p className='text-smtext font-slightly-bold'>{!archived ? archivedText : unarchivedText}</p>
        <div className='flex gap-3 md:gap-5'>
          <Button className=' w-36 h-10 mt-5 rounded-sm' onClick={onClose}>Cancel</Button>
          <Button type="primary" className='bg-primary h-10 w-36 mt-5 rounded-sm' onClick={handleDepartmentArchive} loading={loading}>Confirm</Button>
        </div>

      </div>
    </Modal>
  )
}
