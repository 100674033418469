import MainLayout from "../../layouts/MainLayout";
import DashboardTabs from "../../components/DashboardTabs";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

function Main() {

  const {activeOrganization, isAuthenticated} = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (!activeOrganization) {
        navigate('/organization')
      }
    } else {
      navigate('/login')
    }
  }, [activeOrganization, navigate, isAuthenticated])

  if (!activeOrganization) return null;

  return (
      <MainLayout>
        <div className="py-8 md:p-5 font-poppins">
          <div className="flex flex-col md:flex-row my-2 justify-between">
            <h1 className="text-2xl  font-semibold mb-3 pb-10 md:pb-0">
              Dashboard
            </h1>
          </div>

          <div className="justify-between flex">
            <DashboardTabs/>
          </div>
        </div>
      </MainLayout>
  );
}

export default Main;
