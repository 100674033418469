import {Button, Form, Input, Select, Typography, message, message as Notify} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import timezoneList from "timezones-list";
import Uploader from '../../../../components/Uploader';
import { getUserDetails } from '../../../../features/auth/authActions';
import Api from '../../../../utils/Axios';

export default function Overview() {

  const [orgForm] = useForm();
  const [loading, setLoading] = useState(false);
  const { activeOrganization } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const timezones = useMemo(
    () =>
      timezoneList.map((item) => ({
        value: item.tzCode,
        label: item.label.replace('GMT', 'UTC'),
      })),
    []
  );

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      await Api.Put(`organization/${activeOrganization.id}`, values)
      await dispatch(getUserDetails());
      message.success('Settings updated successfully')
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, dispatch])

  const handleSuccess = useCallback((data) => {
    handleFormSubmit({
      name: activeOrganization.name,
      timezone: activeOrganization.timezone,
      logo: data
    });

  }, [activeOrganization, handleFormSubmit]);

  useEffect(() => {
    if (activeOrganization) {
      orgForm.setFieldsValue({
        name: activeOrganization.name,
        timezone: activeOrganization.timezone
      })
    }
  }, [activeOrganization, orgForm])

  return (
    <div className="bg-white rounded-md p-5 h-full">
      <div className="flex gap-x-10">
        <div className="">
          <Uploader
            src={activeOrganization.logo}
            onSuccess={handleSuccess}
          />
        </div>
        <div className="flex-1">
          <Form form={orgForm} onFinish={handleFormSubmit}>
            <div className="flex gap-x-10 justify-between">
              <div className='flex-1'>
                <Typography.Paragraph className='font-poppins'>
                  Organization Name
                </Typography.Paragraph>
                <Form.Item
                  name="name"
                  rules={[{ required: true, }]}
                >
                  <Input placeholder='Organization Name' className='h-12' />
                </Form.Item>
              </div>
              <div className="flex-1">
                <Typography.Paragraph className='font-poppins'>
                  Timezone
                </Typography.Paragraph>
                <Form.Item
                  name="timezone"
                  rules={[{ required: true, message: "Please select timezone" }]}
                >
                  <Select
                    placeholder="Select timezone"
                    className="h-12"
                    options={timezones}
                    showSearch
                  />
                </Form.Item>
              </div>
            </div>
            <Button htmlType='submit' type='primary' className='h-10 w-36 font-semibold font-poppins' loading={loading} disabled={loading}>
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}
