import { Avatar, Table, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import DepartmentDeleteModal from "./DepartmentDeleteModal";
import DepartmentModal from "./DepartmentModal";
import Api from "../../utils/Axios";
import { useSelector } from "react-redux";
import DepartmentIcon from "./DepartmentIcon";

export default function DepartmentTable({ shouldRefresh, refreshed, isArchived }) {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [departments, setDepartments] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const getDepartments = useCallback(async () => {
    try {
      let params = {};
      if (isArchived) {
        params.archived = true;
      }
      const { data } = await Api.Get(`/organization/${activeOrganization?.id}/department`, params);
      setDepartments(data);
      if (shouldRefresh) refreshed()
    } catch (error) {
      console.error(error);
    }
  }, [activeOrganization, refreshed, shouldRefresh, isArchived]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments])

  useEffect(() => {
    if (shouldRefresh) getDepartments()
  }, [shouldRefresh, getDepartments])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = (refresh) => {
    setIsModalOpen(false);
    if (refresh) {
      setSelectedDepartment(undefined)
      getDepartments()
    }
  };

  const handleEdit = (department) => {
    showModal();
    setSelectedDepartment(department);
  };

  const handleDepartmentArchive = useCallback((department) => {
    setSelectedDepartment(department);
    setShowArchiveModal(true);
  }, []);

  const handleDeleteModalCancel = useCallback((refresh) => {
    setShowArchiveModal(false);
    setSelectedDepartment(undefined)
    if (refresh) {
      getDepartments()
    }

  }, [getDepartments]);

  const columns = [
    {
      title: "Department",
      dataIndex: "name",
      render: (text, record) => (
        <div className='flex gap-2 items-center mr-4'>
          <DepartmentIcon
            selected={false}
            value={record.color}
            size='small'
          />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Members",
      dataIndex: "members",
      render: (members) => (
        <>
          {members ? (
            <div className='avatar'>
              <Avatar.Group
                maxCount={2}
                maxPopoverTrigger='hover'
                size='default'
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                }}>
                {members.map((item, index) => (
                  <Tooltip
                    title={item.name}
                    placement='top'>
                    <Avatar
                      key={item.avatar}
                      src={item?.avatar}
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          ) : (
            <p className='ml-6'>No member available</p>
          )}
        </>
      ),
    },
    {
      title: "Department Head",
      dataIndex: "department_head",
      render: (record) => (
        <>
          {record ? (
            <div className='flex gap-2 items-center'>
              <img
                src={record.avatar}
                className='w-10 h-10 rounded-full object-cover object-top'
                alt='head'
              />
              <p>{record.name}</p>
            </div>
          ) : (
            <p className='ml-6'>No head assigned</p>
          )}
        </>
      ),
    },
    {
      ...(['admin'].includes(activeOrganization?.role) && {
        title: "Action",
        key: "action",
        width: 150,
        render: (_, record) => (
          <div className='flex gap-3'>
            {!isArchived && (
              <button
                className='w-28 text-white rounded-md py-1 bg-[#73C255]'
                onClick={() => handleEdit(record)}>
                Edit
              </button>
            )}
            <button
              className='w-24 text-white rounded-md py-1 bg-[#EE3636]'
              onClick={() => handleDepartmentArchive(record)}>
              {isArchived ? 'Unarchive' : 'Archive'}
            </button>
          </div>
        ),
      }),
    }
  ];

  return (
    <div className='overflow-auto'>
      <Table
        dataSource={departments}
        columns={columns}
        pagination={true}
      />
      <DepartmentDeleteModal
        archived={isArchived}
        department={selectedDepartment}
        onClose={handleDeleteModalCancel}
        open={showArchiveModal}
      />
      <DepartmentModal
        onClose={handleCancel}
        open={isModalOpen}
        department={selectedDepartment}
      />
    </div>
  );
}
