import { Button, DatePicker, Form, Input, Modal, Select, Typography, message as Notify, Radio, Row, Col, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../utils/Axios';
import dayjs from 'dayjs';

export default function MeetingModal({ open, onClose, meeting = undefined }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [meetingForm] = useForm();
  const meetingType = useWatch('type', meetingForm)
  const meetingDate = useWatch('date', meetingForm)


  const getOrganizationUsers = useCallback(async () => {
    try {
      const { data } = await Api.Get(`/organization/${activeOrganization?.id}/users`);
      setUsers(() => {
        return data.map(item => ({ value: item.id, label: item.name }))
      });
    } catch (error) {

    }
  }, [activeOrganization]);

  const handleModalClose = useCallback((refresh = false) => {
    meetingForm.resetFields();
    onClose(refresh)
  }, [onClose, meetingForm])

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true);

      values = {
        ...values,
        date: dayjs(values.date).format('YYYY-MM-DD'),
        start_time: values.time[0].replace(' PM', '').replace(' AM', ''),
        end_time: values.time[1].replace(' PM', '').replace(' AM', ''),
      }

      delete values['time'];

      let call;

      if (meeting) {
        call = () => Api.Put(`/organization/${activeOrganization?.id}/meeting/${meeting?.id}`, values);
      } else {
        call = () => Api.Post(`/organization/${activeOrganization?.id}/meeting`, values);
      }

      const { message } = await call();

      meetingForm.resetFields();
      Notify.success(message);
      handleModalClose(true)

    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const validationErrors = Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key],
        }));
        meetingForm.setFields(validationErrors);
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, meetingForm, meeting, handleModalClose]);

  useEffect(() => {
    if (meeting) {
      const ref = {
        ...meeting,
        date: dayjs(meeting.start_time),
        time: [
          dayjs(meeting.start_time).format('HH:mm A'),
          dayjs(meeting.end_time).format('HH:mm A'),
        ],
        attendees: meeting.attendees?.map(member => member.id) ?? []
      }
      meetingForm.setFieldsValue(ref);
    }
  }, [meeting, meetingForm])

  useEffect(() => {
    getOrganizationUsers()
  }, [getOrganizationUsers])

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => handleModalClose()}
        className='mt-0'
        footer={false}
        width={700}
      >
        <div className='font-poppins'>
          <h1 className='font-slightly-bold text-lg'>{meeting ? "Edit Meeting" : "Schedule new meeting"}</h1>
          <Form layout='vertical' className='mt-4 meeting' onFinish={handleFormSubmit} form={meetingForm} disabled={loading} initialValues={{ type: 'online' }}>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Title</Typography.Paragraph>
              <Form.Item name="title" rules={[{ required: true, message: 'Please input Meeting Title' }]}>
                <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter your meeting title' />
              </Form.Item>
            </div>

            <div className='flex gap-3'>
              <div className='w-1/2'>
                <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Date</Typography.Paragraph>
                <Form.Item name="date" rules={[{ required: true, message: 'Please pick date' }]}>
                  <DatePicker
                    className='w-full px-2 py-2 border rounded-md'
                    disabledDate={(current) => current && current.startOf('day').isBefore(dayjs().startOf('day'))}
                    inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className='w-1/2'>
                <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Time</Typography.Paragraph>
                <Form.Item format='HH:mm' name="time" rules={[{ required: true, message: 'Please select time' }]}>
                  <CustomTimePicker date={meetingDate} />
                </Form.Item>
              </div>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Attendees</Typography.Paragraph>
              <Form.Item className='multi' name="attendees" rules={[{ required: true, message: 'Please input Attendees' }]}>
                <Select
                  mode="multiple"
                  // tagRender={<ProjectTags />}
                  style={{ width: '100%' }}
                  placeholder="Select attendees"
                  options={users}
                />
              </Form.Item>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Meeting Type</Typography.Paragraph>
              <Form.Item name='type'>
                <Radio.Group defaultValue='online'>
                  <Radio value='online'>Online</Radio>
                  <Radio value='in-person'>In Person</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {
              meetingType === 'online' && (
                <div>
                  <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Meeting URL</Typography.Paragraph>
                  <Form.Item name="link" rules={[{ required: true, message: 'Please enter meeting url', type: 'url' }]}>
                    <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter meeting url' />
                  </Form.Item>
                </div>
              )
            }

            {
              meetingType === 'in-person' && (
                <div>
                  <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Location</Typography.Paragraph>
                  <Form.Item name="location" rules={[{ required: true, message: 'Please enter meeting location' }]}>
                    <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter meeting location' />
                  </Form.Item>
                </div>
              )
            }

            <div className='flex gap-5'>
              <Button type="primary" htmlType='submit' className='w-1/2 bg-primary h-10 mt-5 rounded-md' loading={loading}>{meeting ? 'Update' : 'Create'}</Button>
              <Button className=' w-1/2 h-10 mt-5 rounded-md' onClick={onClose}>Cancel</Button>
            </div>
          </Form>
        </div>

      </Modal>
    </div>
  )
}

const CustomTimePicker = ({ value, onChange, date }) => {

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const isTimePassed = useCallback((time) => {
    if (!date) return true;

    const selectedDate = date
    const slotTime = dayjs(`${selectedDate.format('MM/DD/YYYY')} ${time}`, 'MM/DD/YYYY HH:mm');
    const currentTime = dayjs();

    if (selectedDate.isAfter(currentTime, 'day')) {
      return false;
    }

    if (selectedDate.isSame(currentTime, 'day') && currentTime.isAfter(slotTime)) {
      return true;
    }

    if (selectedDate.isBefore(currentTime, 'day')) {
      return true;
    }

    return false;
  }, [date]);

  const timeSlots = useMemo(() => {
    const slots = [];
    let start = dayjs().startOf('day');

    while (start.isBefore(dayjs().endOf('day'))) {
      slots.push({
        label: start.format('hh:mm A'),
        value: start.format('HH:mm A')
      });

      start = start.add(15, 'minute');
    }

    return slots;
  }, []);


  useEffect(() => {
    if (startTime && endTime) {
      if (startTime === endTime) {
        message.error('Start & End time cannot be same');
      }

      if (endTime < startTime) {
        message.error('End time cannot be earlier than start time');
      }
      onChange([startTime, endTime]);
    }
    // eslint-disable-next-line
  }, [startTime, endTime])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setStartTime(value[0])
      setEndTime(value[1])
    }
  }, [value])

  return (
    <Row gutter={12}>
      <Col span={12}>
        <Select
          className='h-10'
          placeholder="Select start time"
          value={startTime}
          onChange={value => setStartTime(value)}
        >
          {
            timeSlots.map(time => (
              <Select.Option value={time.value} disabled={isTimePassed(time.value)}>
                {time.label}
              </Select.Option>

            ))
          }
        </Select>

      </Col>
      <Col span={12}>
        <Select
          className='h-10'
          placeholder="Select end time"
          value={endTime}
          onChange={value => setEndTime(value)}
        >
          {
            timeSlots.map(time => (
              <Select.Option value={time.value} disabled={isTimePassed(time.value)}>
                {time.label}
              </Select.Option>

            ))
          }
        </Select>
      </Col>
    </Row >
  )
}
