import React, {createContext, useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';

const HistoryContext = createContext({});

export const HistoryProvider = ({children}) => {
  const location = useLocation();
  const [history, setHistory] = useState([]);

  React.useEffect(() => {
    setHistory((prev) => [...prev, location.pathname]);
  }, [location]);

  return (
      <HistoryContext.Provider value={history}>
        {children}
      </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
