import { Button, Tag } from "antd";
import React, { useCallback, useState } from "react";
import DepartmentModal from "../../components/Department/DepartmentModal";
import DepartmentTable from "../../components/Department/DepartmentTable";
import MainLayout from "../../layouts/MainLayout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Authorized from "../../components/Middleware/Authorized";

export default function Department() {

  const { activeOrganization } = useSelector((state) => state.auth)
  const [refreshTable, setRefreshTable] = useState(false);
  const [archive, setArchive] = useState(false);
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const navigate = useNavigate();
  const handleCancel = useCallback((refresh) => {
    setShowDepartmentModal(false);
    if (refresh) {
      setRefreshTable(refreshTable)
    }
  }, [refreshTable]);

  return (
    <MainLayout>
      <Authorized permission='team-management'>
        <div className="py-8 md:p-5 font-poppins">
          <div className='flex flex-col md:flex-row my-2 justify-between items-center'>
            <div className="flex items-center gap-x-6 mt-4 mb-7">
              <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
              </Button>
              <h1 className="text-2xl font-semibold flex items-center">
                Departments
                <Tag
                  className="ml-2 shadow-lg"
                  color={archive ? 'red' : 'green'}
                >
                  {archive ? "Archived" : "Active"}
                </Tag>
              </h1>
            </div>
            <div className='flex gap-3 '>
              {
                activeOrganization?.role === 'admin' && (
                  <>
                    <Button
                      onClick={() => setArchive((prevArchive) => !prevArchive)}
                      className='w-full font-poppins rounded-md h-10'>
                      {!archive ? "Archived Departments" : "Active Departments"}
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => setShowDepartmentModal(true)}
                      className='bg-primary font-poppins h-10 rounded-md'>
                      Create Department
                    </Button>
                  </>
                )
              }
            </div>
          </div>
          <DepartmentTable
            isArchived={archive}
            shouldRefresh={refreshTable}
            refreshed={() => setRefreshTable(false)}
          />
          <DepartmentModal
            onClose={handleCancel}
            open={showDepartmentModal}
          />
        </div>
      </Authorized>
    </MainLayout>
  );
}
