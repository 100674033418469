import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../../utils/Axios';
import { Button, Table } from 'antd';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PaymentModal from './PaymentModal';

const StatusMap = {
  'pending': 'Pending',
  'awaiting-approval': 'Awaiting Approval',
  'approved': 'Approved',
  'rejected': 'Reject'
}

export default function Billing() {

  const [selectedInvoice, setSelectedInvoice] = useState(undefined);
  const [activePlan, setActivePlan] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth);
  const [invoices, setInvoices] = useState([]);

  const getBillingDetail = useCallback(async () => {
    try {
      const { data } = await Api.Get(`/organization/${activeOrganization.id}/invoice`)
      setInvoices(data.invoices);
      setActivePlan(data.plan);
    } catch (errors) {
      console.error(errors)
    }
  }, [activeOrganization]);

  const handlePaymentModalClose = useCallback((refresh = false) => {
    if (refresh) getBillingDetail()
    setSelectedInvoice(undefined)
  }, [getBillingDetail])

  const columns = [

    {
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item) => (
        <span className={clsx({
          'px-3 py-1 font-poppins text-xs rounded-md': true,
          'text-[#5E1414] bg-[#FFEEEE]': item === 'rejected',
          'text-[#145E20] bg-[#F3FFEE]': item === 'approved',
          'text-[#a19f2b] bg-[#FFFBB8]': ['pending', 'awaiting-approval'].includes(item)
        })}>{StatusMap[item]}</span>
      )
    },

    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (item) => {
        return <a href={item} target="blank" className='text-primary underline'>View Invoice</a>
      }
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        if (['pending', 'rejected'].includes(record.status)) {
          return (
            <Button
              type='primary'
              className='font-poppins'
              onClick={() => setSelectedInvoice(record)}
            >
              Make payment
            </Button>
          );
        }
        return null;
      }
    },
  ]

  useEffect(() => {
    getBillingDetail()
  }, [getBillingDetail])

  return (
    <div>
      <div className="bg-white rounded-md p-5 h-full">
        <div className="flex justify-between items-center">
          <div className="">
            <span className="text-base font-semibold flex items-center gap-x-3">Current Plan:
            </span>
            <h2 className='text-4xl font-extrabold text-primary'>
              {activePlan?.name}
              {
                activePlan?.on_trial && (
                  <span className='text-black text-sm font-bold inline-block ml-2'>Free Trial</span>
                )
              }
            </h2>
            {
              (activePlan?.expires_at) && (
                <span>{activePlan.expires_at}</span>
              )
            }
            <p className='mt-1'>
              {/*Current Bill: <span className='font-semibold'>{activePlan?.current_payable}</span>*/}
              Price: <span className='font-semibold'>{activePlan?.currency} {activePlan?.price}</span>
            </p>
          </div>
          {/*<Link to='/plans' className='bg-primary px-5 py-1.5 rounded-md text-white hover:bg-blue-400 transition-colors'>
            Manage
          </Link>*/}
        </div>
      </div>

      <div className="bg-white rounded-md p-5 h-full mt-5">
        <h2 className="text-poppins text-2xl font-semibold">
          Invoices
        </h2>
        <Table
          className='mt-5'
          columns={columns}
          dataSource={invoices.data}
          pagination={{ position: ['bottom-center'] }}
        />

        <PaymentModal
          invoice={selectedInvoice}
          open={!!selectedInvoice}
          onClose={handlePaymentModalClose}
        />
      </div>
    </div>
  )
}
