import React, { useMemo, useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import Authorized from '../../../components/Middleware/Authorized';
import { useSelector } from 'react-redux';
import { TfiTrash } from "react-icons/tfi";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import { PiSelectionPlusLight } from "react-icons/pi";
import { TfiReceipt } from "react-icons/tfi";
import DeleteOrganizationModal from '../../../components/Modals/DeleteOrganizationModal';
import Overview from './components/Overview';
import Screenshots from './components/Screenshots';
import Billing from './components/Billing';
import clsx from 'clsx';

export default function Settings() {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');

  const tabs = useMemo(() => [
    {
      key: '1',
      label: 'Overview',
      icon: <TfiReceipt size={25} />,
      component: <Overview />
    },
    {
      key: '2',
      label: 'Screenshots',
      icon: <PiSelectionPlusLight size={25} />,
      component: <Screenshots />
    },
    {
      key: '3',
      label: 'Billing & Plans',
      icon: <PiCurrencyCircleDollar size={25} />,
      component: <Billing />
    },
  ], []);

  return (
    <MainLayout>
      <Authorized permission={activeOrganization?.role === 'admin'}>
        <div className="py-8 md:p-5 font-poppins">
          <h2 className='text-xl font-semibold'>Organization Settings</h2>
          <div className="flex gap-x-5 mt-5">
            <div className="bg-white py-10 px-5 rounded-md">
              <ul className='space-y-7'>
                {
                  tabs.map(item => (
                    <>
                      <li className={clsx({
                        'text-sm flex gap-x-3 items-center hover:text-primary cursor-pointer font-semibold': true,
                        'text-primary': activeTabKey === item.key
                      })} key={`item-${item.key}`} onClick={() => setActiveTabKey(item.key)}>
                        {item.icon}
                        {item.label}
                      </li>
                    </>
                  ))
                }
                <li className='invisible text-sm flex gap-x-3 items-center text-red-500 cursor-pointer font-semibold' onClick={() => setShowDeleteModal(true)}>
                  <TfiTrash size={25} />
                  Delete your Organization
                </li>
              </ul>
            </div>
            <div className="flex-1  ">
              {tabs.find(tab => tab.key === activeTabKey)?.component}
            </div>
          </div>

          <DeleteOrganizationModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
        </div>
      </Authorized>
    </MainLayout>
  )
}
