import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Typography, Button, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import LogoutModal from "../../components/LogoutModal";
import Api from "../../utils/Axios";
import { useDispatch } from "react-redux";
import { setActiveOrganization } from "../../features/auth/authSlice";
import AuthLayout from "../../layouts/AuthLayout";
import Auth from "../../components/Middleware/Auth";


export default function Organization() {
  const [showLogout, setShowLogout] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationForm] = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = ({ organization }) => {
    const activeOrg = organizations.find((item) => item.id === organization);
    dispatch(setActiveOrganization(activeOrg));
    navigate("/");
  };

  const getOrganizations = useCallback(async () => {
    try {
      const { data } = await Api.Get("organization");
      setOrganizations(data);
    } catch (error) {
      console.error("Something went wrong");
    }
  }, []);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  useEffect(() => {
    if (organizations.length === 1 && !organizations[0].disabled) {
      dispatch(setActiveOrganization(organizations[0]));
      navigate("/");
    }
  }, [organizations, navigate, dispatch]);

  return (
    <Auth>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Select your <span className="text-secondary">Workotick</span>{" "}
            organization
          </Typography.Title>
          <Typography.Paragraph className="font-poppins font-light">
            You're part of multiple innovative teams! Please select the
            organization you wish to access today.
          </Typography.Paragraph>
        </div>
        <Form
          layout="vertical"
          className="mt-0 "
          onFinish={handleFormSubmit}
          form={organizationForm}
        >
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Your organization*
            </Typography.Paragraph>
            <Form.Item
              name="organization"
              rules={[{ required: true, message: "Select Organization" }]}
            >
              <Select
                className="h-12"
                allowClear
                placeholder="Select your organization"
              >
                {organizations.map((organization) => {
                  return (
                    <Select.Option
                      value={organization.id}
                      key={`organization-${organization.id}`}
                      className="py-2 border-b"
                      disabled={organization.disabled}
                    >
                      <div className="flex">
                        <img src={organization.logo} alt="" width={30} />
                        <p className="m-1 mt-1">{organization.name}</p>
                      </div>
                    </Select.Option>
                  );
                })}
                {/*<Select.Option value="" key="new-org-link" className="py-2">
                  <Link to="/create-organization">
                    <Typography.Paragraph className="text-primary font-poppins mb-0">
                      + Create new organization
                    </Typography.Paragraph>
                  </Link>
                </Select.Option>*/}
              </Select>
            </Form.Item>
          </div>
          <Form.Item className="mt-6 mb-2">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-primary text-white font-poppins text-medium"
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
        <div className="text-right">
          <Button
            className="font-poppins py-0"
            type="link"
            onClick={() => setShowLogout(true)}
          >
            Logout
          </Button>
        </div>
        {/*<div className="sf-new-org-hr relative mt-5">
          <Link to="/create-organization">
            <Typography.Paragraph className="font-poppins text-center mx-auto max-w-[200px] bg-white">
              Create new <span className="text-primary">Organization</span>
            </Typography.Paragraph>
          </Link>
        </div>*/}
        <LogoutModal show={showLogout} onClose={() => setShowLogout(false)} />
      </AuthLayout>
    </Auth>
  );
}
